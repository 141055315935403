var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("h2", { staticClass: "mt-2 mb-8 titleColor--text" }, [
        _vm._v("Totalizadores"),
      ]),
      _c(
        "v-card",
        {
          scopedSlots: _vm._u(
            [
              _vm.isAdmin
                ? {
                    key: "top",
                    fn: function () {
                      return [
                        _c(
                          "v-toolbar",
                          { attrs: { flat: "", color: "backgroundSecond" } },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-btn",
                              {
                                staticClass: "mb-2",
                                attrs: { color: "primary", dense: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.open()
                                  },
                                },
                              },
                              [_vm._v(" Adicionar crédito ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.totalizadores,
              loading: _vm.loading,
              "footer-props": {
                "items-per-page-options": [10, 25, 50],
              },
              "sort-by": "razaoSocial",
              "sort-desc": false,
            },
            scopedSlots: _vm._u(
              [
                _vm.isAdmin
                  ? {
                      key: "top",
                      fn: function () {
                        return [
                          _c(
                            "v-toolbar",
                            { attrs: { flat: "", color: "backgroundSecond" } },
                            [
                              _c("v-spacer"),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mb-2",
                                  attrs: {
                                    color: "primary",
                                    dense: "",
                                    id: "btnAddCredit",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.open()
                                    },
                                  },
                                },
                                [_vm._v(" Adicionar crédito ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
                _vm.isAdmin
                  ? {
                      key: "item.actions",
                      fn: function ({ item }) {
                        return [
                          _c(
                            "AGTooltip",
                            { attrs: { "tooltip-text": "Editar" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    id: "btnEdit",
                                    icon: "",
                                    title: "Editar",
                                    color: "primary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(item)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { small: "" } }, [
                                    _vm._v(" mdi-pencil "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    }
                  : null,
                {
                  key: "item.valor",
                  fn: function ({ item }) {
                    return [
                      _c("td", { staticClass: "bgColorRed" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("emptyCurrency")(_vm._f("money")(item.valor))
                          )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.expectativaTotalCredito",
                  fn: function ({ item }) {
                    return [
                      _c("td", { staticClass: "bgColorRed" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("emptyCurrency")(
                              _vm._f("money")(item.expectativaTotalCredito)
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.valorPrescricao",
                  fn: function ({ item }) {
                    return [
                      _c("td", { staticClass: "bgColorRed" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("emptyCurrency")(
                              _vm._f("money")(item.valorPrescricao)
                            )
                          )
                        ),
                      ]),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
        ],
        1
      ),
      _c("modal-edit-totalizadores", {
        ref: "modalTotalizadores",
        attrs: {
          "credito-origin": _vm.credito,
          totalizadores: _vm.totalizadores,
        },
        on: {
          clean: function ($event) {
            return _vm.clear()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }