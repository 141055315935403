<template>
  <v-main>
    <h2 class="mt-2 mb-8 titleColor--text">Totalizadores</h2>

    <v-card>
      <template v-slot:top v-if="isAdmin">
        <v-toolbar flat color="backgroundSecond">
          <v-spacer></v-spacer>
          <v-btn color="primary" dense class="mb-2" @click="open()"> Adicionar crédito </v-btn>
        </v-toolbar>
      </template>
      <v-data-table
        :headers="headers"
        :items="totalizadores"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 25, 50],
        }"
        sort-by="razaoSocial"
        :sort-desc="false"
        class="elevation-1"
      >
        <template v-slot:top v-if="isAdmin">
          <v-toolbar flat color="backgroundSecond">
            <v-spacer></v-spacer>
            <v-btn color="primary" dense class="mb-2" @click="open()" id="btnAddCredit"> Adicionar crédito </v-btn>
          </v-toolbar>
        </template>

        <template v-if="isAdmin" v-slot:item.actions="{ item }">
          <AGTooltip tooltip-text="Editar">
            <v-btn id="btnEdit" icon title="Editar" color="primary" @click="edit(item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
          </AGTooltip>
        </template>

        <template v-slot:item.valor="{ item }">
          <td class="bgColorRed">{{ item.valor | money | emptyCurrency }}</td>
        </template>
        <template v-slot:item.expectativaTotalCredito="{ item }">
          <td class="bgColorRed">{{ item.expectativaTotalCredito | money | emptyCurrency }}</td>
        </template>
        <template v-slot:item.valorPrescricao="{ item }">
          <td class="bgColorRed">{{ item.valorPrescricao | money | emptyCurrency }}</td>
        </template>
      </v-data-table>
    </v-card>
    <modal-edit-totalizadores
      ref="modalTotalizadores"
      :credito-origin="credito"
      :totalizadores="totalizadores"
      @clean="clear()"
    />
  </v-main>
</template>
<script>
import TotalizadorService from '@/services/totalizadorService.js';
import * as moment from 'moment';
//API Trabalhadores
import { mapActions, mapGetters } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: 'Totalizadores',
  components: {
    ModalEditTotalizadores: () => import('./components/ModalTotalizadores.vue'),
    AGTooltip: () => import('../commons/AGTooltip.vue'),
  },

  data() {
    return {
      credito: {},
      usuarioNovo: {
        emailAddress: '',
        username: '',
        firstName: '',
        lastName: '',
        password: '',
        clientId: 'web-front-end',
        authorities: [],
      },

      loading: true,
      show: false,
      dialogUsuario: false,
      rules: {
        required: (value) => !!value || 'Required.',
      },

      headers: [
        { text: 'Razão social', align: 'start', value: 'razaoSocial', width: '30%' },
        { text: 'CNPJ raiz', value: 'cnpjRaiz' },
        { text: 'Valor', value: 'valor' },
        { text: 'Expectativa total de crédito', value: 'expectativaTotalCredito' },
        { text: 'Data da prescrição', value: 'dataPrescricao' },
        { text: 'Valor da prescrição', value: 'valorPrescricao' },
        { text: 'Ações', value: 'actions', align: 'center', sortable: false, align: ' d-none' },
      ],

      totalizadores: [],
    };
  },

  computed: {
    ...mapGetters({
      usuario: 'user/getUserDetails',
      perfis: 'user/userAuthorities',
    }),
  },

  created() {
    this.getTotalizadores();
  },
  beforeMount() {
    this.getTotalizadores();
  },

  methods: {
    ...mapActions('usuarios', ['getUsers', 'getEstablishments', 'changeEstablishments', 'getRoles', 'changeRoles']),
    ...mapActions('user', ['getUser']),

    open() {
      this.credito = {};

      this.$refs.modalTotalizadores.$emit('open');
    },
    edit(item) {
      if (item) {
        this.credito = cloneDeep(item);

        this.credito.dataPrescricao === undefined
          ? (this.credito.dataPrescricao = moment().format('YYYY-MM-DD'))
          : (this.credito.dataPrescricao = this.formatDate(this.credito.dataPrescricao, 'YYYY-MM-DD'));

        this.credito.valor = this.formatCurrencyValue(item.valor);
        this.credito.valorPrescricao = this.formatCurrencyValue(item.valorPrescricao);
        this.credito.expectativaTotalCredito = this.formatCurrencyValue(item.expectativaTotalCredito);

        this.$refs.modalTotalizadores.$emit('edit');
      }
    },
    formatDate(date, format) {
      let otherFormat = format === 'YYYY-MM-DD' ? 'DD/MM/YYYY' : 'YYYY-MM-DD';
      if (!date) return null;
      if (moment(date, format, true).isValid()) {
        return date;
      }
      return moment(moment(date, otherFormat)).format(format);
    },
    formatCurrencyValue(value) {
      if (value) {
        let formated = value;
        return formated;
      } else {
        return '0.0';
      }
    },
    clear() {
      this.credito = {};
      this.getTotalizadores();
    },
    async getTotalizadores() {
      let adminOrNot = '';
      if (this.perfis != undefined) {
        adminOrNot = this.perfis.filter((n) => n === 'ROLE_ADMIN');
      } else {
        this.getUsers();
        adminOrNot = this.perfis.filter((n) => n === 'ROLE_ADMIN');
      }

      if (adminOrNot.length > 0) {
        this.headers.filter((n) => {
          if (n.text === 'Ações') n = n.align = '';
        });
        this.isAdmin = true;
      }
      let response = await TotalizadorService.financial.getAll();
      this.totalizadores = response.data;
      this.loading = false;
    },
  },
};
</script>
